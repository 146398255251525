<template>
  <landing />
</template>

<script>
  import Landing from '../components/Landing'

  export default {
    name: 'Home',

    components: {
      Landing,
    },
  }
</script>
